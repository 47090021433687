import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "textArea",
      label: "code",
      model: "code",
      placeholder: "code",
      required: true,
      max: 2048,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("codeRequired"),
          textTooBig: i18n.t("codeMustBeLess{1}")
        }),
      ]
    },
    {
      type: "textArea",
      label: "translation",
      model: "translation",
      placeholder: "translation",
      required: true,
      max: 2048,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("translationRequired"),
          textTooBig: i18n.t("translationMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "module",
      model: "module",
      required: false,
      placeholder: "module",
      max: 255,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("moduleMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "language",
      model: "language",
      required: true,
      placeholder: "language",
      max: 3,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("languageRequired"),
          textTooBig: i18n.t("languageMustBeLess{1}")
        }),
      ]
    },
  ]
};
